<script lang="ts" setup>
import type { RewardsData } from "@/types";

const props = defineProps<{
	rewards: RewardsData;
}>();

const { open } = useFunrizeModals();
const ONE_THOUSAND = 1000;

const rewardsAmount = computed(() => {
	const value = Object.keys(props.rewards).reduce((acc, key) => {
		const reward = props.rewards[key as keyof RewardsData];
		const { available, completed, coins } = reward;

		if (available && !completed) {
			return acc + coins;
		}
		return acc;
	}, 0);

	return value >= ONE_THOUSAND ? `${value / ONE_THOUSAND}K` : value;
});

const handleClick = () => {
	open("LazyOModalProfileRewards");
	dispatchGAEvent({ event: "open_popup", formName: "rewards", location: "header", step: "info" });
};
</script>
<template>
	<AText
		class="topline text-cannes"
		data-tid="header-topline-club"
		as="div"
		variant="toledo"
		:modifiers="['extrabold']"
		@click="handleClick"
	>
		<NuxtImg src="/nuxt-img/wheel.png" alt="wheel" width="44" height="28" format="webp" />
		<i18n-t keypath="Unlock Funrize Club - get {key} Coins">
			<template #key>
				{{ rewardsAmount }}
			</template>
		</i18n-t>
		<AAnimationZoomInOut :finish-scale="1.3">
			<NuxtIcon name="16/arrow-prev" filled />
		</AAnimationZoomInOut>
	</AText>
</template>
<style lang="scss" scoped>
.topline {
	width: 100%;
	min-height: 32px;
	padding: gutter(0.5) gutter(2);
	gap: gutter(1);
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--gwalior);
	z-index: 2;
	line-height: 20px;
	cursor: pointer;

	.nuxt-icon {
		font-size: 16px;
		display: inline-block;
		transform: scaleX(-1);
		margin-left: gutter(0.25);
	}

	img {
		margin-bottom: gutter(-0.5);
	}

	@include media-breakpoint-down(lg) {
		align-items: flex-start;
		padding: gutter(0.75) gutter(2);
	}
}
</style>
